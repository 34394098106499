import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function homeCarouselHorizontal() {
  
  const homeCarousel = document.getElementById('home-carousel');
  if (!homeCarousel) return;
  
  if (homeCarousel.scrollWidth <= window.innerWidth) return;

  let cards = gsap.utils.toArray(".js-home-new-carousel-item");

  // Scroll animation
  let endValue = window.innerWidth < 768 ? "+=2000" : "+=4000"; // Shorter duration on mobile

  gsap.to(cards, {
    x: -homeCarousel.scrollWidth, 
    ease: "none",
    scrollTrigger: {
      trigger: '.js-home-new-carousel',
      scrub: 1,
      start: "top bottom",
      end: endValue,
    }
  });
}

export default function() {
  homeCarouselHorizontal();
}
